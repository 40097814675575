/*
 * Font Stylesheet
 *
 * Loading is deferred in order to improve performance
 */
/*!
 *  OpenSans
 */
/* BEGIN Light */
@font-face {
  font-family: "Open Sans";
  src: url(../font/691f774319fda1ccf9ea172ffaee815e.woff2) format("woff2"), url(../font/edab3663655deaddbc4baf33e83838dd.woff) format("woff");
  font-style: normal;
  font-weight: 300; }

/* END Light */
/* BEGIN Regular */
@font-face {
  font-family: "Open Sans";
  src: url(../font/e78dce533ecee30c5efd812bb23c248d.woff2) format("woff2"), url(../font/552ea4cf95b85269f5a3b5b1e2b2eedb.woff) format("woff");
  font-style: normal;
  font-weight: normal; }

/* END Regular */
/* BEGIN Italic */
@font-face {
  font-family: "Open Sans";
  src: url(../font/d4955e33747c4000180f9460f383c901.woff2) format("woff2"), url(../font/077a0a0bac06768f03631be17f050000.woff) format("woff");
  font-style: italic;
  font-weight: normal; }

/* END Italic */
/* BEGIN Semibold */
@font-face {
  font-family: "Open Sans";
  src: url(../font/e9681ca3d29d814a5621d4764dd1a11e.woff2) format("woff2"), url(../font/1d8cbd42230b32f36e03e5fa9cfc27bc.woff) format("woff");
  font-style: normal;
  font-weight: 600; }

/* END Semibold */
/* BEGIN Bold */
@font-face {
  font-family: "Open Sans";
  src: url(../font/96f3835aa784a280a0e1e7fa64b97b60.woff2) format("woff2"), url(../font/8dd1fba73ff945cf492243e58a13877e.woff) format("woff");
  font-style: normal;
  font-weight: bold; }

/* END Bold */
.t-open-sans {
  font-family: "Open Sans", Arial, Helvetica, sans-serif; }
